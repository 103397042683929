import {
  Check,
  ChevronsUpDown,
  CirclePlus,
  Loader,
  Palette,
} from "lucide-react"
import { useState } from "react"
import { useFetcher } from "react-router"

import { Button } from "~/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/components/ui/command"
import { Input } from "~/components/ui/input"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover"
import { cn } from "~/lib/utils"
import type { RecentStyles } from "../uploads/recentStyles"

let recentStylesRoute = "/api/styles"

type SelectStyleProps = {
  onChange: (value: File | string | undefined) => void
}

export function SelectStyle({ onChange }: SelectStyleProps) {
  let [open, setOpen] = useState(false)
  let [storageKey, setStorageKey] = useState("")
  let [fileName, setFileName] = useState("")
  let fetcher = useFetcher<RecentStyles>()

  return (
    <Popover
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen)

        if (isOpen) {
          fetcher.load(recentStylesRoute)
        }
      }}
    >
      <Input
        type="file"
        id="style-file-input"
        name="styleFile"
        className="hidden"
        onChange={(e) => {
          let files = e.target.files

          if (files) {
            let file = files[0]
            if (file) {
              setFileName(file.name)
              setStorageKey("")
              onChange(file)
            }
          }
        }}
      />
      <Input
        readOnly
        className="hidden"
        name="styleStorageKey"
        value={storageKey}
      />
      <PopoverTrigger asChild>
        <Button variant="secondary" aria-expanded={open}>
          <Palette />
          {storageKey &&
            fetcher.data?.find((style) => style.storageKey === storageKey)
              ?.label}
          {fileName && <span>{fileName}</span>}
          {!storageKey && !fileName && "Style"}
          <ChevronsUpDown className="opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-fit p-0" align="start">
        <Command>
          <CommandInput
            aria-label="Search styles"
            placeholder="Search"
            className="h-9"
            name="query"
            onChangeCapture={(e) => {
              let encodedQuery = encodeURIComponent(e.currentTarget.value)
              fetcher.load(`${recentStylesRoute}?q=${encodedQuery}`)
            }}
          />
          <CommandList>
            <CommandEmpty>No style found.</CommandEmpty>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  document.getElementById("style-file-input")?.click()
                  setOpen(false)
                }}
              >
                <CirclePlus />
                Upload style guide
              </CommandItem>
            </CommandGroup>
            <CommandGroup
              heading={
                <span className="flex gap-2 align-center">
                  Recent styles
                  {fetcher.state === "loading" && (
                    <Loader className="animate-spin h-4 w-4" />
                  )}
                </span>
              }
            >
              {fetcher.data?.map((item) => (
                <CommandItem
                  key={item.storageKey}
                  value={item.storageKey}
                  onSelect={(current) => {
                    setStorageKey(current === storageKey ? "" : current)
                    setFileName("")
                    setOpen(false)
                  }}
                >
                  {item.label}
                  <Check
                    className={cn(
                      "ml-auto",
                      storageKey === item.storageKey ? "block" : "hidden",
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
